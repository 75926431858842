import * as constants from "./constants";

const initialState = {
  loadingPelanggan: true,
  loadingPelangganBlokir: true,
  loadingPelangganVerifikasi: true,
  pelanggan: [],
  pelangganBlokir: [],
  pelangganVerifikasi: [],
  idPelanggan: 0,
  idPelangganBlokir: 0,
  idPelangganDelete: 0,
  totalPelanggan: 0,
  totalPelangganBlokir: 0,
  totalPelangganVerifikasi: 0,
  loadingTransaksiProduk: true,
  loadingTransaksiDeposit: true,
  loadingTransaksiPengecekan: true,
  loadingSupplierReply: true,
  loadingCreateTransactionReply: true,
  loadingFeedback: true,
  loadingCheckMutasiSalahTransfer: null,
  statusSalahTransfer: "",
  transaksiProduk: [],
  transaksiDeposit: [],
  metodeDeposit: [],
  transaksiPengecekan: [],
  supplierReply: [],
  supplierReplyFilter: null,
  createTransactionReply: [],
  totalTransaksiProduk: 0,
  totalTransaksiDeposit: 0,
  totalTransaksiPengecekan: 0,
  totalSupplierReply: 0,
  totalCreateTransactionReply: 0,
  feedback: [],
  totalFeedback: 0,

  infoDataPelanggan: {},
  infoDataProduk: {},
  infoDataDeposit: {},
  infoDataPengecekan: {},
  infoReplyNobu: null,
  infoCheckMutasiSalahTransfer: null,
  infoSalahTransfer: null,
  mutasiSalahTransfer: null,
  actionTransaksi: {},
  failedEditReferensi: true,
  failedEditTotal: true,
  infoDataFeedback: {},
  kategoriFeedback: [],
  statusBacklog: 0,
  blacklist: [],
  totalBlacklist: 0,
  loadingBlacklist: true,
  forbiddenPelanggan: false,
  forbiddenPelangganBlokir: false,
  forbiddenTransaksiProduk: false,
  forbiddenTransaksiDeposit: false,
  forbiddenTransaksiPengecekan: false,
  forbiddenSupplierReply: false,
  forbiddenCreateTransactionReply: false,
  forbiddenFeedback: false,
  forbiddenBlacklist: false,
  forbiddenInfoDataProduk: false,
  forbiddenInfoDataDeposit: false,
  forbiddenInfoDataPengecekan: false,
  forbiddenInfoDataFeedback: false,

  //log delete user
  loadingLogDeleteUser: true,
  logDeleteUser: [],
  totalLogDeleteUser: 0,
  statusLogDeleteUser: "",
  infoDataLogDeleteUser: {},
  forbiddenLogDeleteUser: false,
  forbiddenInfoDataLogDeleteUser: false,
};

export const pelangganReducer = (state = initialState, action) => {
  let newFeedback;
  let idxFeedback;
  let newBlacklist;
  let idxBlacklist;
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case constants.GET_PELANGGAN:
      return {
        ...state,
        loadingPelanggan: true,
        forbiddenPelanggan: initialState.forbiddenPelanggan,
      };
    case constants.GET_PELANGGAN_SUCCESS:
      return {
        ...state,
        loadingPelanggan: false,
        pelanggan: action.payload.data,
        totalPelanggan: action.payload.total,
      };
    case constants.GET_PELANGGAN_FAILED:
      return {
        ...state,
        loadingPelanggan: false,
        pelanggan: initialState.pelanggan,
        forbiddenPelanggan: action.payload === 403,
      };
    case constants.BANNED_PELANGGAN_SUCCESS:
      return {
        ...state,
        idPelanggan: action.payload,
      };
    case constants.GET_PELANGGAN_BLOKIR:
      return {
        ...state,
        loadingPelangganBlokir: true,
        forbiddenPelangganBlokir: initialState.forbiddenPelangganBlokir,
      };
    case constants.GET_PELANGGAN_BLOKIR_SUCCESS:
      return {
        ...state,
        loadingPelangganBlokir: false,
        pelangganBlokir: action.payload.data,
        totalPelangganBlokir: action.payload.total,
      };
    case constants.GET_PELANGGAN_BLOKIR_FAILED:
      return {
        ...state,
        loadingPelangganBlokir: false,
        pelangganBlokir: initialState.pelangganBlokir,
        forbiddenPelangganBlokir: action.payload === 403,
      };
    case constants.GET_PELANGGAN_VERIFIKASI_SUCCESS:
      return {
        ...state,
        loadingPelangganVerifikasi: false,
        pelangganVerifikasi: action.payload,
        totalPelangganVerifikasi: action.payload.length,
      };
    case constants.GET_PELANGGAN_VERIFIKASI_FAILED:
      return {
        ...state,
        loadingPelangganVerifikasi: false,
        pelangganVerifikasi: initialState.pelangganVerifikasi,
        totalPelangganVerifikasi: initialState.totalPelangganVerifikasi,
      };
    case constants.OPEN_BANNED_PELANGGAN_SUCCESS:
      return {
        ...state,
        idPelangganBlokir: action.payload,
      };
    case constants.DELETE_PELANGGAN_SUCCESS:
      return {
        ...state,
        idPelangganDelete: action.payload,
      };
    case constants.GET_TRANSAKSI_PRODUK:
      return {
        ...state,
        loadingTransaksiProduk: true,
        forbiddenTransaksiProduk: initialState.forbiddenTransaksiProduk,
      };
    case constants.GET_TRANSAKSI_PRODUK_SUCCESS:
      return {
        ...state,
        loadingTransaksiProduk: false,
        transaksiProduk: action.payload.data,
        totalTransaksiProduk: action.payload.total,
      };
    case constants.GET_TRANSAKSI_PRODUK_FAILED:
      return {
        ...state,
        loadingTransaksiProduk: false,
        transaksiProduk: initialState.transaksiProduk,
        forbiddenTransaksiProduk: action.payload === 403,
      };
    case constants.GET_DETAIL_TRANSAKSI_PRODUK:
      return {
        ...state,
        forbiddenInfoDataProduk: initialState.forbiddenInfoDataProduk,
      };
    case constants.GET_DETAIL_TRANSAKSI_PRODUK_SUCCESS:
      return {
        ...state,
        infoDataPelanggan: action.payload.pelanggan,
        infoDataProduk: action.payload.produk,
        actionTransaksi: action.payload.action,
      };
    case constants.GET_DETAIL_TRANSAKSI_PRODUK_FAILED:
      return {
        ...state,
        infoDataPelanggan: {},
        infoDataProduk: {},
        actionTransaksi: 0,
        forbiddenInfoDataProduk: action.payload === 403,
      };
    case constants.GET_TRANSAKSI_DEPOSIT:
      return {
        ...state,
        loadingTransaksiDeposit: true,
        forbiddenTransaksiDeposit: initialState.forbiddenTransaksiDeposit,
      };
    case constants.GET_TRANSAKSI_DEPOSIT_SUCCESS:
      return {
        ...state,
        loadingTransaksiDeposit: false,
        transaksiDeposit: action.payload.data,
        totalTransaksiDeposit: action.payload.total,
      };
    case constants.GET_TRANSAKSI_DEPOSIT_FAILED:
      return {
        ...state,
        loadingTransaksiDeposit: false,
        transaksiDeposit: initialState.transaksiDeposit,
        forbiddenTransaksiDeposit: action.payload === 403,
      };
    case constants.GET_METODE_DEPOSIT_SUCCESS:
      return {
        ...state,
        metodeDeposit: action.payload,
      };
    case constants.GET_METODE_DEPOSIT_FAILED:
      return {
        ...state,
        metodeDeposit: initialState.transaksiDeposit,
      };
    case constants.GET_DETAIL_TRANSAKSI_DEPOSIT:
      return {
        ...state,
        infoDataDeposit: initialState.infoDataDeposit,
        forbiddenInfoDataDeposit: initialState.forbiddenInfoDataDeposit,
      };
    case constants.GET_DETAIL_TRANSAKSI_DEPOSIT_SUCCESS:
      return {
        ...state,
        infoDataPelanggan: action.payload.pelanggan,
        infoDataDeposit: action.payload.produk,
        actionTransaksi: action.payload.action,
      };
    case constants.GET_DETAIL_TRANSAKSI_DEPOSIT_FAILED:
      return {
        ...state,
        infoDataPelanggan: {},
        infoDataDeposit: {},
        actionTransaksi: 0,
        forbiddenInfoDataDeposit: action.payload === 403,
      };
    case constants.SUKSESKAN_TRANSAKSI_DEPOSIT_SUCCESS:
      return {
        ...state,
        infoReplyNobu: null,
      };
    case constants.GET_TRANSAKSI_PENGECEKAN:
      return {
        ...state,
        loadingTransaksiPengecekan: true,
        forbiddenTransaksiPengecekan: initialState.forbiddenTransaksiPengecekan,
      };
    case constants.GET_TRANSAKSI_PENGECEKAN_SUCCESS:
      return {
        ...state,
        loadingTransaksiPengecekan: false,
        transaksiPengecekan: action.payload.data,
        totalTransaksiPengecekan: action.payload.total,
      };
    case constants.GET_TRANSAKSI_PENGECEKAN_FAILED:
      return {
        ...state,
        loadingTransaksiPengecekan: false,
        transaksiPengecekan: initialState.transaksiPengecekan,
        forbiddenTransaksiPengecekan: action.payload === 403,
      };
    case constants.GET_DETAIL_TRANSAKSI_PENGECEKAN:
      return {
        ...state,
        forbiddenInfoDataPengecekan: initialState.forbiddenInfoDataPengecekan,
      };
    case constants.GET_DETAIL_TRANSAKSI_PENGECEKAN_SUCCESS:
      return {
        ...state,
        infoDataPelanggan: action.payload.pelanggan,
        infoDataPengecekan: action.payload.produk,
      };
    case constants.GET_DETAIL_TRANSAKSI_PENGECEKAN_FAILED:
      return {
        ...state,
        infoDataPelanggan: {},
        infoDataPengecekan: {},
        forbiddenInfoDataPengecekan: action.payload === 403,
      };
    case constants.GET_LIST_FEEDBACK:
      return {
        ...state,
        loadingFeedback: true,
        forbiddenFeedback: initialState.forbiddenFeedback,
      };
    case constants.GET_LIST_FEEDBACK_SUCCESS:
      return {
        ...state,
        loadingFeedback: false,
        feedback: action.payload.data,
        totalFeedback: action.payload.total,
      };
    case constants.GET_LIST_FEEDBACK_FAILED:
      return {
        ...state,
        loadingFeedback: false,
        feedback: initialState.feedback,
        totalFeedback: initialState.totalFeedback,
        forbiddenFeedback: action.payload === 403,
      };
    case constants.GET_DETAIL_FEEDBACK:
      return {
        ...state,
        forbiddenInfoDataFeedback: initialState.forbiddenInfoDataFeedback,
      };
    case constants.GET_DETAIL_FEEDBACK_SUCCESS:
      return {
        ...state,
        infoDataFeedback: action.payload,
        statusBacklog: action.payload.backlog,
      };
    case constants.GET_DETAIL_FEEDBACK_FAILED:
      return {
        ...state,
        infoDataFeedback: {},
        statusBacklog: 0,
        forbiddenInfoDataFeedback: action.payload === 403,
      };
    case constants.BACKLOG_FEEDBACK_SUCCESS:
      newFeedback = [...state.feedback];
      idxFeedback = newFeedback.findIndex((x) => x.id === action.payload);
      newFeedback[idxFeedback].backlog = 1;
      return {
        ...state,
        feedback: newFeedback,
      };
    case constants.CANCEL_BACKLOG_FEEDBACK_SUCCESS:
      newFeedback = [...state.feedback];
      idxFeedback = newFeedback.findIndex((x) => x.id === action.payload);
      newFeedback[idxFeedback].backlog = 0;
      return {
        ...state,
        feedback: newFeedback,
      };
    case constants.GET_LIST_KATEGORI_FEEDBACK_SUCCESS:
      return {
        ...state,
        kategoriFeedback: action.payload,
      };
    case constants.GET_LIST_KATEGORI_FEEDBACK_FAILED:
      return {
        ...state,
        kategoriFeedback: initialState.kategoriFeedback,
      };
    case constants.GET_STATUS_NOBU_SUCCESS:
      return {
        ...state,
        infoReplyNobu: action.payload.responseDetail.data,
      };

    case constants.GET_LIST_LOGDELETE_USER:
      return {
        ...state,
        loadingLogDeleteUser: true,
        forbiddenLogDeleteUser: initialState.forbiddenLogDeleteUser,
      };
    case constants.GET_LIST_LOGDELETE_USER_SUCCESS:
      return {
        ...state,
        loadingLogDeleteUser: false,
        logDeleteUser: action.payload.data,
        totalLogDeleteUser: action.payload.total,
      };
    case constants.GET_LIST_LOGDELETE_USER_FAILED:
      return {
        ...state,
        loadingLogDeleteUser: false,
        logDeleteUser: initialState.LogDeleteUser,
        totalLogDeleteUser: initialState.totalLogDeleteUser,
        forbiddenLogDeleteUser: action.payload === 403,
      };
    case constants.GET_DETAIL_LOGDELETE_USER:
      return {
        ...state,
        forbiddenInfoDataLogDeleteUser:
          initialState.forbiddenInfoDataLogDeleteUser,
      };
    case constants.GET_DETAIL_LOGDELETE_USER_SUCCESS:
      return {
        ...state,
        infoDataLogDeleteUser: action.payload,
        statusBacklog: action.payload.backlog,
      };
    case constants.GET_DETAIL_LOGDELETE_USER_FAILED:
      return {
        ...state,
        infoDataLogDeleteUser: {},
        statusBacklog: 0,
        forbiddenInfoDataLogDeleteUser: action.payload === 403,
      };
    case constants.UPDATE_LOGDELETE_USER:
      return {
        ...state,
        statusLogDeleteUser: "loading",
      };
    case constants.UPDATE_LOGDELETE_USER_SUCCESS:
      return {
        ...state,
        statusLogDeleteUser: "success",
      };
    case constants.UPDATE_LOGDELETE_USER_FAILED:
      return {
        ...state,
        statusLogDeleteUser: "failed",
      };
    case constants.DELETE_LOGDELETE_USER:
      return {
        ...state,
        statusLogDeleteUser: "loading",
        infoDataLogDeleteUser: initialState.infoDataLogDeleteUser,
      };
    case constants.DELETE_LOGDELETE_USER_SUCCESS:
      return {
        ...state,
        statusLogDeleteUser: "success",
        infoDataLogDeleteUser: action.payload,
      };
    case constants.DELETE_LOGDELETE_USER_FAILED:
      return {
        ...state,
        statusLogDeleteUser: "failed",
        infoDataLogDeleteUser: action.payload,
      };
    case constants.UPDATE_LOG_DELETE_USER_LOCAL:
      return {
        ...state,
        logDeleteUser: state.logDeleteUser.map((item) =>
          item.id === action.payload.id
            ? { ...item, status: action.payload.status }
            : item
        ),
      };
    case constants.DELETE_LOG_DELETE_USER_LOCAL:
      return {
        ...state,
        logDeleteUser: state.logDeleteUser.filter(
          (item) => item.id !== action.payload
        ),
      };
    case constants.UPDATE_DETAIL_LOG_DELETE_USER_LOCAL:
      return {
        ...state,
        infoDataLogDeleteUser: {
          ...state.infoDataLogDeleteUser,
          status: action.payload,
        },
      };

    case constants.INIT_SALAH_TRANSFER:
      return {
        ...state,
        loadingCheckMutasiSalahTransfer:
          initialState.loadingCheckMutasiSalahTransfer,
        infoCheckMutasiSalahTransfer: initialState.infoCheckMutasiSalahTransfer,
        mutasiSalahTransfer: initialState.mutasiSalahTransfer,
      };
    case constants.GET_MUTASI_SALAH_TRANSFER:
      return {
        ...state,
        loadingCheckMutasiSalahTransfer: true,
        infoCheckMutasiSalahTransfer: initialState.infoCheckMutasiSalahTransfer,
        mutasiSalahTransfer: initialState.mutasiSalahTransfer,
      };
    case constants.GET_MUTASI_SALAH_TRANSFER_SUCCESS:
      return {
        ...state,
        loadingCheckMutasiSalahTransfer: false,
        infoCheckMutasiSalahTransfer: action.payload,
      };
    case constants.GET_MUTASI_SALAH_TRANSFER_FAILED:
      return {
        ...state,
        loadingCheckMutasiSalahTransfer: false,
        infoCheckMutasiSalahTransfer: initialState.infoCheckMutasiSalahTransfer,
      };
    case constants.SUKSESKAN_SALAH_TRANSFER:
      return {
        ...state,
        statusSalahTransfer: "loading",
        infoSalahTransfer: initialState.infoSalahTransfer,
      };
    case constants.SUKSESKAN_SALAH_TRANSFER_SUCCESS:
      return {
        ...state,
        statusSalahTransfer: "success",
        infoSalahTransfer: action.payload,
      };
    case constants.SUKSESKAN_SALAH_TRANSFER_FAILED:
      return {
        ...state,
        statusSalahTransfer: "failed",
        infoSalahTransfer: action.payload,
      };
    case constants.GET_STATUS_NOBU_FAILED:
      return {
        ...state,
        infoReplyNobu: initialState.infoReplyNobu,
      };

    case constants.GET_SUPPLIER_REPLY:
      return {
        ...state,
        loadingSupplierReply: true,
        forbiddenSupplierReply: initialState.forbiddenSupplierReply,
      };
    case constants.GET_SUPPLIER_REPLY_SUCCESS:
      return {
        ...state,
        loadingSupplierReply: false,
        supplierReply: action.payload.data,
        totalSupplierReply: action.payload.total,
      };
    case constants.GET_SUPPLIER_REPLY_FAILED:
      return {
        ...state,
        loadingSupplierReply: false,
        supplierReply: initialState.supplierReply,
        forbiddenSupplierReply: action.payload === 403,
      };
    case constants.GET_CREATE_TRANSACTION_REPLY:
      return {
        ...state,
        loadingCreateTransactionReply: true,
        forbiddenCreateTransactionReply:
          initialState.forbiddenCreateTransactionReply,
      };
    case constants.GET_CREATE_TRANSACTION_REPLY_SUCCESS:
      return {
        ...state,
        loadingCreateTransactionReply: false,
        createTransactionReply: action.payload.data,
        totalCreateTransactionReply: action.payload.total,
      };
    case constants.GET_CREATE_TRANSACTION_REPLY_FAILED:
      return {
        ...state,
        loadingCreateTransactionReply: false,
        createTransactionReply: initialState.createTransactionReply,
        forbiddenCreateTransactionReply: action.payload === 403,
      };
    case constants.SET_FILTER_SUPPLIER_REPLY:
      return {
        ...state,
        supplierReplyFilter: action.payload,
      };
    case constants.RESET_FILTER_SUPPLIER_REPLY:
      return {
        ...state,
        supplierReplyFilter: initialState.supplierReplyFilter,
      };
    case constants.GET_LIST_BLACKLIST:
      return {
        ...state,
        loadingBlacklist: true,
        forbiddenBlacklist: initialState.forbiddenBlacklist,
      };
    case constants.GET_LIST_BLACKLIST_SUCCESS:
      return {
        ...state,
        loadingBlacklist: false,
        blacklist: action.payload.data,
        totalBlacklist: action.payload.total,
      };
    case constants.GET_LIST_BLACKLIST_FAILED:
      return {
        ...state,
        loadingBlacklist: false,
        blacklist: initialState.blacklist,
        totalBlacklist: initialState.totalBlacklist,
        forbiddenBlacklist: action.payload === 403,
      };
    case constants.OPEN_BLACKLIST_SUCCESS:
      newBlacklist = [...state.blacklist];
      idxBlacklist = newBlacklist.findIndex(
        (x) => x.nomor === action.payload.number
      );
      newBlacklist[idxBlacklist].status = action.payload.status;
      return {
        ...state,
        blacklist: newBlacklist,
      };
    case constants.UPDATE_BLACKLIST_SUCCESS:
      newBlacklist = [...state.blacklist];
      idxBlacklist = newBlacklist.findIndex(
        (x) => x.nomor === action.payload.number
      );
      newBlacklist[idxBlacklist].status = action.payload.status;
      return {
        ...state,
        blacklist: newBlacklist,
      };
    case constants.SET_MUTASI_SALAH_TRANSFER:
      return {
        ...state,
        mutasiSalahTransfer: action.payload,
      };
  }
};
