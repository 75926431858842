import moment from "moment";
import * as constants from "./constants";

const initialState = {
  loadingListProduk: true,
  loadingListSetProduk: true,
  loadingProduk: true,
  loadingProvider: true,
  loadingTipe: true,
  loadingSubTipe: true,
  loadingKategori: true,
  failedEditKode: true,
  listProduk: [],
  listSetProduk: [],
  masterProduk: [],
  masterProvider: [],
  masterTipe: [],
  masterSubTipe: [],
  masterKategori: [],
  supplierProduk: [],
  supplierProvider: [],
  totalListProduk: 0,
  totalListSetProduk: 0,
  editProduk: false,
  editDataProduk: [],
  singleDataSetProduk: [],
  listSupplier: [],
  customMarginProduk: [],
  editProvider: false,
  editDataProvider: [],
  editTipe: false,
  editDataTipe: [],
  editSubTipe: false,
  editDataSubTipe: [],
  editKategori: false,
  editDataKategori: [],
  deleteProduk: false,
  maintenance: {},
  masterSupplierV2: [],
  masterProdukV2: [],
  masterProdukTypeV2: [],
  masterProdukTypeDetailV2: [],
  masterProdukProviderV2: [],
  masterProdukCategoryV2: [],
  forbiddenProduk: false,
  forbiddenSetProduk: false,
  forbiddenMasterProduk: false,
  forbiddenMasterProvider: false,
  forbiddenMasterTipe: false,
  forbiddenMasterSubTipe: false,
  forbiddenMasterKategori: false,
  forbiddenEditProduk: false,
  forbiddenMasterSupplierV2: false,
  forbiddenMasterProdukV2: false,
  forbiddenMasterProdukTypeV2: false,
  forbiddenMasterProdukTypeDetailV2: false,
  forbiddenMasterProdukProviderV2: false,
  forbiddenMasterProdukCategoryV2: false,
  searchText: "",
  searchTextEnter: "",
  searchTextOption: "",
  searchPage: 1,

  //supplier detail
  loadingSupplierDetail: true,
  SupplierDetail: [],
  totalSupplierDetail: 0,
  statusSupplierDetail: "",
  infoDataSupplierDetail: {},
  formEditSupplierDetail: {},
  forbiddenSupplierDetail: false,
  forbiddenInfoDataSupplierDetail: false,
};

export const produkReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case constants.GET_LIST_PRODUK:
      return {
        ...state,
        loadingListProduk: true,
        listProduk: [],
        forbiddenProduk: initialState.forbiddenProduk,
      };
    case constants.GET_LIST_PRODUK_SUCCESS:
      return {
        ...state,
        loadingListProduk: false,
        listProduk: action.payload.data,
        totalListProduk: action.payload.total,
      };
    case constants.GET_LIST_PRODUK_FAILED:
      return {
        ...state,
        loadingListProduk: false,
        listProduk: initialState.listProduk,
        forbiddenProduk: action.payload === 403,
      };
    case constants.DELETE_PRODUK_SUCCESS:
      return {
        ...state,
        deleteProduk: action.payload === true ? false : true,
      };
    case constants.SET_STATUS_PRODUK_SUCCESS:
      let newList = [...state.listProduk];
      let index = newList.findIndex(
        (x) => x.code_product === action.payload.code
      );
      if (action.payload.data.status !== undefined) {
        newList[index].status = action.payload.data.status;
      }
      if (action.payload.data.is_visible !== undefined) {
        newList[index].is_visible = action.payload.data.is_visible;
      }
      return {
        ...state,
        listProduk: newList,
      };
    case constants.FORM_PRODUK_ADD_DATA:
      return {
        ...state,
        editProduk: false,
        editDataProduk: {
          product: undefined,
          provider: undefined,
          type: undefined,
          sub_type: undefined,
          category: undefined,
          code_product: "",
          caption: "",
          description: "",
          terms: "",
          zona: "",
        },
        customMarginProduk: {
          code_product: null,
          id_margin: null,
          margin: "0",
          start_date: moment(),
          start_time: moment(moment(), "HH:mm:ss"),
          end_date: moment(),
          end_time: moment(moment(), "HH:mm:ss"),
        },
      };
    case constants.GET_PRODUK_EDIT_DATA:
      return {
        ...state,
        forbiddenEditProduk: initialState.forbiddenEditProduk,
      };
    case constants.GET_PRODUK_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editProduk: true,
        listSupplier: action.payload.list_supplier,
        editDataProduk: action.payload.detail,
        singleDataSetProduk: action.payload.supplier,
        customMarginProduk: action.payload.customMargin,
      };
    case constants.GET_PRODUK_EDIT_DATA_FAILED:
      return {
        ...state,
        editProduk: false,
        editDataProduk: null,
        forbiddenEditProduk: action.payload === 403,
      };
    case constants.SET_PRODUK_EDIT:
      return {
        ...state,
        editProduk: action.payload,
      };
    case constants.GET_LIST_SET_PRODUK:
      return {
        ...state,
        loadingListSetProduk: true,
        forbiddenSetProduk: initialState.forbiddenSetProduk,
      };
    case constants.GET_LIST_SET_PRODUK_SUCCESS:
      return {
        ...state,
        loadingListSetProduk: false,
        listSetProduk: action.payload,
      };
    case constants.GET_LIST_SET_PRODUK_FAILED:
      return {
        ...state,
        loadingListSetProduk: false,
        listSetProduk: initialState.listProduk,
        forbiddenSetProduk: action.payload === 403,
      };
    case constants.GET_TOTAL_LIST_SET_PRODUK_SUCCESS:
      return {
        ...state,
        totalListSetProduk: action.payload,
      };
    case constants.GET_TOTAL_LIST_SET_PRODUK_FAILED:
      return {
        ...state,
        totalListSetProduk: initialState.totalListProduk,
      };
    case constants.GET_SET_PRODUK_BY_CODE_SUCCESS:
      return {
        ...state,
        singleDataSetProduk: action.payload,
      };
    case constants.SET_FAILED_EDIT_KODE_INADD_SUCCESS:
      return {
        ...state,
        failedEditKode: action.payload,
      };
    case constants.SET_FAILED_EDIT_KODE_INADD_FAILED:
      return {
        ...state,
        failedEditKode: action.payload,
      };
    case constants.SET_FAILED_EDIT_KODE_INDETAIL_SUCCESS:
      return {
        ...state,
        failedEditKode: action.payload,
      };
    case constants.SET_FAILED_EDIT_KODE_INDETAIL_FAILED:
      return {
        ...state,
        failedEditKode: action.payload,
      };
    case constants.GET_MASTER_PRODUK:
      return {
        ...state,
        forbiddenMasterProduk: initialState.forbiddenMasterProduk,
      };
    case constants.GET_MASTER_PRODUK_SUCCESS:
      return {
        ...state,
        loadingProduk: false,
        masterProduk: action.payload,
      };
    case constants.GET_MASTER_PRODUK_FAILED:
      return {
        ...state,
        loadingProduk: false,
        masterProduk: initialState.masterProduk,
        forbiddenMasterProduk: action.payload === 403,
      };
    case constants.SET_PREMIUM_MASTER_PRODUK_SUCCESS:
      let newMasterProduk = [...state.masterProduk];
      let idx = newMasterProduk.findIndex(
        (x) => x.id_product === action.payload.id_product
      );
      newMasterProduk[idx].is_premium = action.payload.is_premium;
      return {
        ...state,
        masterProduk: newMasterProduk,
      };
    case constants.GET_MASTER_PROVIDER:
      return {
        ...state,
        forbiddenMasterProvider: initialState.forbiddenMasterProvider,
      };
    case constants.GET_MASTER_PROVIDER_SUCCESS:
      return {
        ...state,
        loadingProvider: false,
        masterProvider: action.payload,
      };
    case constants.GET_MASTER_PROVIDER_FAILED:
      return {
        ...state,
        loadingProvider: false,
        masterProvider: initialState.masterProvider,
        forbiddenMasterProvider: action.payload === 403,
      };
    case constants.GET_MASTER_TIPE:
      return {
        ...state,
        forbiddenMasterTipe: initialState.forbiddenMasterTipe,
      };
    case constants.GET_MASTER_TIPE_SUCCESS:
      return {
        ...state,
        loadingTipe: false,
        masterTipe: action.payload,
      };
    case constants.GET_MASTER_TIPE_FAILED:
      return {
        ...state,
        loadingTipe: false,
        masterTipe: initialState.masterTipe,
        forbiddenMasterTipe: action.payload === 403,
      };
    case constants.GET_MASTER_SUBTIPE:
      return {
        ...state,
        forbiddenMasterSubTipe: initialState.forbiddenMasterSubTipe,
      };
    case constants.GET_MASTER_SUBTIPE_SUCCESS:
      return {
        ...state,
        loadingSubTipe: false,
        masterSubTipe: action.payload,
        forbiddenMasterSubTipe: action.payload === 403,
      };
    case constants.GET_MASTER_SUBTIPE_FAILED:
      return {
        ...state,
        loadingSubTipe: false,
        masterSubTipe: initialState.masterSubTipe,
      };
    case constants.GET_MASTER_KATEGORI:
      return {
        ...state,
        forbiddenMasterKategori: initialState.forbiddenMasterKategori,
      };
    case constants.GET_MASTER_KATEGORI_SUCCESS:
      return {
        ...state,
        loadingKategori: false,
        masterKategori: action.payload,
      };
    case constants.GET_MASTER_KATEGORI_FAILED:
      return {
        ...state,
        loadingKategori: false,
        masterKategori: initialState.masterKategori,
        forbiddenMasterKategori: action.payload === 403,
      };
    case constants.GET_SUPPLIER_PRODUK_SUCCESS:
      return {
        ...state,
        supplierProduk: action.payload,
      };
    case constants.GET_SUPPLIER_PRODUK_FAILED:
      return {
        ...state,
        supplierProduk: initialState.supplierProduk,
      };
    case constants.GET_SUPPLIER_PROVIDER_SUCCESS:
      return {
        ...state,
        supplierProvider: action.payload,
      };
    case constants.GET_SUPPLIER_PROVIDER_FAILED:
      return {
        ...state,
        supplierProvider: initialState.supplierProvider,
      };
    case constants.SEARCH_SETDATA_FAILED:
      return {
        ...state,
        loadingProduk: false,
        masterProduk: initialState.masterProduk,
        loadingProvider: false,
        masterProvider: initialState.masterProvider,
        loadingTipe: false,
        masterTipe: initialState.masterTipe,
        loadingSubTipe: false,
        masterSubTipe: initialState.masterSubTipe,
        loadingKategori: false,
        masterKategori: initialState.masterKategori,
      };
    case constants.FORM_PROVIDER_ADD_DATA:
      return {
        ...state,
        editProvider: false,
        editDataProvider: {
          product: "",
          id_product_provider: "",
          id_product: "",
          name: "",
          icon: "",
          type: "",
          description: "",
          status: "",
          is_visible_android: "",
          is_visible_web: "",
        },
      };
    case constants.FORM_PROVIDER_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editProvider: true,
        editDataProvider: action.payload,
      };
    case constants.FORM_PROVIDER_EDIT_DATA_FAILED:
      return {
        ...state,
        editProvider: true,
        editDataProvider: initialState.editDataProvider,
      };
    case constants.FORM_TIPE_ADD_DATA:
      return {
        ...state,
        editTipe: false,
        editDataTipe: {
          product: "",
          id_product: "",
          id_product_type: "",
          name: "",
        },
      };
    case constants.FORM_TIPE_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editTipe: true,
        editDataTipe: action.payload,
      };
    case constants.FORM_TIPE_EDIT_DATA_FAILED:
      return {
        ...state,
        editTipe: true,
        editDataTipe: initialState.editDataTipe,
      };
    case constants.FORM_SUBTIPE_ADD_DATA:
      return {
        ...state,
        editSubTipe: false,
        editDataSubTipe: {
          product: "",
          id_product_type: "",
          id_product_type_detail: "",
          name: "",
          type: "",
        },
      };
    case constants.FORM_SUBTIPE_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editSubTipe: true,
        editDataSubTipe: action.payload,
      };
    case constants.FORM_SUBTIPE_EDIT_DATA_FAILED:
      return {
        ...state,
        editSubTipe: true,
        editDataSubTipe: initialState.editDataSubTipe,
      };
    case constants.FORM_KATEGORI_ADD_DATA:
      return {
        ...state,
        editKategori: false,
        editDataKategori: {
          product: "",
          id_product_category: "",
          id_product_provider: "",
          name: "",
          provider: "",
        },
      };
    case constants.FORM_KATEGORI_EDIT_DATA_SUCCESS:
      return {
        ...state,
        editKategori: true,
        editDataKategori: action.payload,
      };
    case constants.FORM_KATEGORI_EDIT_DATA_FAILED:
      return {
        ...state,
        editKategori: true,
        editDataKategori: initialState.editDataKategori,
      };
    case constants.GET_MAINTENANCE_PRODUK_SUCCESS:
      return {
        ...state,
        maintenance: action.payload,
      };
    case constants.GET_MASTER_SUPPLIER_V2:
      return {
        ...state,
        forbiddenMasterSupplierV2: initialState.forbiddenMasterSupplierV2,
      };
    case constants.GET_MASTER_SUPPLIER_V2_SUCCESS:
      return {
        ...state,
        masterSupplierV2: action.payload,
      };
    case constants.GET_MASTER_SUPPLIER_V2_FAILED:
      return {
        ...state,
        masterSupplierV2: initialState.masterSupplierV2,
        forbiddenMasterSupplierV2: action.payload === 403,
      };
    case constants.GET_MASTER_PRODUK_V2:
      return {
        ...state,
        masterProdukTypeDetailV2: initialState.masterProdukTypeDetailV2,
        masterProdukProviderV2: initialState.masterProdukProviderV2,
        masterProdukCategoryV2: initialState.masterProdukCategoryV2,
        forbiddenMasterProdukV2: initialState.forbiddenMasterProdukV2,
      };
    case constants.GET_MASTER_PRODUK_V2_SUCCESS:
      return {
        ...state,
        masterProdukV2: action.payload,
      };
    case constants.GET_MASTER_PRODUK_V2_FAILED:
      return {
        ...state,
        masterProdukV2: initialState.masterProdukV2,
        forbiddenMasterProdukV2: action.payload === 403,
      };
    case constants.GET_MASTER_PRODUK_TYPE_V2:
      return {
        ...state,
        masterProdukTypeV2: initialState.masterProdukTypeV2,
        masterProdukTypeDetailV2: initialState.masterProdukTypeDetailV2,
        masterProdukProviderV2: initialState.masterProdukProviderV2,
        masterProdukCategoryV2: initialState.masterProdukCategoryV2,
        forbiddenMasterProdukTypeV2: initialState.forbiddenMasterProdukTypeV2,
      };
    case constants.GET_MASTER_PRODUK_TYPE_V2_SUCCESS:
      return {
        ...state,
        masterProdukTypeV2: action.payload,
      };
    case constants.GET_MASTER_PRODUK_TYPE_V2_FAILED:
      return {
        ...state,
        masterProdukTypeV2: initialState.masterProdukTypeV2,
        forbiddenMasterProdukTypeV2: action.payload === 403,
      };
    case constants.GET_MASTER_PRODUK_TYPE_DETAIL_V2:
      return {
        ...state,
        forbiddenMasterProdukTypeDetailV2:
          initialState.forbiddenMasterProdukTypeDetailV2,
      };
    case constants.GET_MASTER_PRODUK_TYPE_DETAIL_V2_SUCCESS:
      return {
        ...state,
        masterProdukTypeDetailV2: action.payload,
      };
    case constants.GET_MASTER_PRODUK_TYPE_DETAIL_V2_FAILED:
      return {
        ...state,
        masterProdukTypeDetailV2: initialState.masterProdukTypeDetailV2,
        forbiddenMasterProdukTypeDetailV2: action.payload === 403,
      };
    case constants.GET_MASTER_PRODUK_PROVIDER_V2:
      return {
        ...state,
        masterProdukCategoryV2: initialState.masterProdukCategoryV2,
        forbiddenMasterProdukProviderV2:
          initialState.forbiddenMasterProdukProviderV2,
      };
    case constants.GET_MASTER_PRODUK_PROVIDER_V2_SUCCESS:
      return {
        ...state,
        masterProdukProviderV2: action.payload,
      };
    case constants.GET_MASTER_PRODUK_PROVIDER_V2_FAILED:
      return {
        ...state,
        masterProdukProviderV2: initialState.masterProdukProviderV2,
        forbiddenMasterProdukProviderV2: action.payload === 403,
      };
    case constants.GET_MASTER_PRODUK_CATEGORY_V2:
      return {
        ...state,
        forbiddenMasterProdukCategoryV2:
          initialState.forbiddenMasterProdukCategoryV2,
      };
    case constants.GET_MASTER_PRODUK_CATEGORY_V2_SUCCESS:
      return {
        ...state,
        masterProdukCategoryV2: action.payload,
      };
    case constants.GET_MASTER_PRODUK_CATEGORY_V2_FAILED:
      return {
        ...state,
        masterProdukCategoryV2: initialState.masterProdukCategoryV2,
        forbiddenMasterProdukCategoryV2: action.payload === 403,
      };
    case constants.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.payload,
      };
    case constants.SET_SEARCH_TEXT_ENTER:
      return {
        ...state,
        searchTextEnter: action.payload,
      };
    case constants.SET_SEARCH_TEXT_OPTION:
      return {
        ...state,
        searchTextOption: action.payload,
      };

    case constants.GET_LIST_SUPPLIER_DETAIL:
      return {
        ...state,
        loadingSupplierDetail: true,
        forbiddenSupplierDetail: initialState.forbiddenSupplierDetail,
      };
    case constants.GET_LIST_SUPPLIER_DETAIL_SUCCESS:
      return {
        ...state,
        loadingSupplierDetail: false,
        SupplierDetail: action.payload.data,
        totalSupplierDetail: action.payload.total,
      };
    case constants.GET_LIST_SUPPLIER_DETAIL_FAILED:
      return {
        ...state,
        loadingSupplierDetail: false,
        SupplierDetail: initialState.SupplierDetail,
        totalSupplierDetail: initialState.totalSupplierDetail,
        forbiddenSupplierDetail: action.payload === 403,
      };
    case constants.GET_DETAIL_SUPPLIER_DETAIL:
      return {
        ...state,
        forbiddenInfoDataSupplierDetail:
          initialState.forbiddenInfoDataSupplierDetail,
      };
    case constants.GET_DETAIL_SUPPLIER_DETAIL_SUCCESS:
      return {
        ...state,
        infoDataSupplierDetail: action.payload,
        formEditSupplierDetail: action.payload,
        statusBacklog: action.payload.backlog,
      };
    case constants.GET_DETAIL_SUPPLIER_DETAIL_FAILED:
      return {
        ...state,
        infoDataSupplierDetail: {},
        statusBacklog: 0,
        forbiddenInfoDataSupplierDetail: action.payload === 403,
      };
    case constants.UPDATE_SUPPLIER_DETAIL:
      return {
        ...state,
        statusSupplierDetail: "loading",
      };
    case constants.UPDATE_SUPPLIER_DETAIL_SUCCESS:
      return {
        ...state,
        statusSupplierDetail: "success",
      };
    case constants.UPDATE_SUPPLIER_DETAIL_FAILED:
      return {
        ...state,
        statusSupplierDetail: "failed",
      };
    case constants.DELETE_SUPPLIER_DETAIL:
      return {
        ...state,
        statusSupplierDetail: "loading",
        infoDataSupplierDetail: initialState.infoDataSupplierDetail,
      };
    case constants.DELETE_SUPPLIER_DETAIL_SUCCESS:
      return {
        ...state,
        statusSupplierDetail: "success",
        infoDataSupplierDetail: action.payload,
      };
    case constants.DELETE_SUPPLIER_DETAIL_FAILED:
      return {
        ...state,
        statusSupplierDetail: "failed",
        infoDataSupplierDetail: action.payload,
      };

    case constants.RESET_FORM_EDIT_SUPPLIER_DETAIL:
      return {
        ...state,
        formEditSupplierDetail: {}, // Reset the form data
      };
  }
};
