import moment from "moment";
import * as constants from "./constants";

const initialState = {
  totalTransaksiProduk: 0,
  totalTransaksiDeposit: 0,
  totalTransaksiPengecekan: 0,
  totalMutasiSaldo: 0,
  totalLogUpdatePhone: 0,
  loadingDetailPelanggan: true,
  loadingSavePelanggan: false,
  loadingTransaksiProduk: true,
  loadingTransaksiDeposit: true,
  loadingTransaksiPengecekan: true,
  loadingMutasiSaldo: true,
  loadingLogUpdatePhone: true,
  detailPelanggan: {
    active: 0,
    balance: 0,
    is_open_limit: 0,
    img_account: "",
    nama: "",
    status: "",
    telp: "",
    ktp: "",
    selfie: "",
    img_toko: "",
    id: "",
    nik: "",
    agama: "",
    tempat_lahir: "",
    tanggal_lahir: "",
    gender: "",
    id_kabupaten: "",
    kabupaten: "",
    id_kecamatan: "",
    kecamatan: "",
    toko: "",
    alamat: "",
    thumbnail_verif: "",
    video_verif: "",
    reason: "-",
    unverified_message: "-",
    log_update_phone: [],
  },
  listKabupaten: [],
  listKecamatan: [],
  transaksiProduk: [],
  transaksiDeposit: [],
  transaksiPengecekan: [],
  mutasiSaldo: [],
  logUpdatePhone: [],
  loadingPerangkat: true,
  loadingKeamanan: true,
  perangkat: [],
  keamanan: [],
  otp: {},
  otpList: [],
  resetOtp: false,
  resetPin: false,
  forbiddenDetailPelanggan: false,
  forbiddenTransaksiProduk: false,
  forbiddenTransaksiDeposit: false,
  forbiddenTransaksiPengecekan: false,
  forbiddenMutasiSaldo: false,
  forbiddenLogUpdatePhone: false,
  forbiddenPerangkat: false,
  forbiddenKeamanan: false,
};

export const detailPelangganReducer = (state = initialState, action) => {
  let newList;
  switch (action.type) {
    default:
      return {
        ...state,
      };
    case constants.GET_LIST_KABUPATEN_SUCCESS:
      return {
        ...state,
        listKabupaten: action.payload,
      };
    case constants.GET_LIST_KECAMATAN_SUCCESS:
      return {
        ...state,
        listKecamatan: action.payload,
      };
    case constants.GET_DETAIL_PELANGGAN:
      return {
        ...state,
        loadingDetailPelanggan: true,
        forbiddenDetailPelanggan: initialState.forbiddenDetailPelanggan,
      };
    case constants.GET_DETAIL_PELANGGAN_SUCCESS:
      return {
        ...state,
        loadingDetailPelanggan: false,
        detailPelanggan: {
          active: action.payload.active,
          balance: action.payload.balance,
          is_open_limit: action.payload.is_open_limit,
          img_account: action.payload.img_account,
          nama: action.payload.nama,
          status: action.payload.status,
          telp: action.payload.telp,
          ktp: action.payload.ktp,
          selfie: action.payload.selfie,
          img_toko: action.payload.img_toko,
          id: action.payload.id,
          nik: action.payload.nik,
          agama: action.payload.agama,
          tempat_lahir: action.payload.tempat_lahir,
          tanggal_lahir: action.payload.tanggal_lahir,
          gender:
            action.payload.gender === "male"
              ? "Laki-laki"
              : action.payload.gender === "female"
              ? "Perempuan"
              : "",
          id_kabupaten: action.payload.id_kabupaten,
          kabupaten: action.payload.kabupaten,
          id_kecamatan: action.payload.id_kecamatan,
          kecamatan: action.payload.kecamatan,
          toko: action.payload.toko,
          alamat: action.payload.alamat,
          thumbnail_verif: action.payload.thumbnail_verif,
          video_verif: action.payload.video_verif,
          log_update_phone: action.payload.log_update_phone,
          reason: action.payload.reason === "" ? "-" : action.payload.reason,
          unverified_message:
            action.payload.unverified_message === "" ||
            action.payload.unverified_message === null
              ? "-"
              : action.payload.unverified_message,
        },
      };
    case constants.GET_DETAIL_PELANGGAN_FAILED:
      return {
        forbiddenDetailPelanggan: action.payload === 403,
      };
    case constants.UPDATE_DATA_PELANGGAN:
      return {
        ...state,
        loadingSavePelanggan: true,
      };
    case constants.UPDATE_DATA_PELANGGAN_SUCCESS:
      let pelanggan = state.detailPelanggan;
      console.log(JSON.stringify(action.payload));
      if (action.payload.type_param === "nik")
        pelanggan.nik = action.payload.nik;
      if (action.payload.type_param === "nama")
        pelanggan.nama = action.payload.nama;
      if (action.payload.type_param === "telp")
        pelanggan.telp = action.payload.telp;
      if (action.payload.type_param === "agama")
        pelanggan.agama = action.payload.agama;
      if (action.payload.type_param === "tempat_lahir")
        pelanggan.tempat_lahir = action.payload.tempat_lahir;
      if (action.payload.type_param === "tanggal_lahir")
        pelanggan.tanggal_lahir = moment(action.payload.tanggal_lahir).format(
          "YYYY-MM-DD"
        );
      if (action.payload.type_param === "gender")
        pelanggan.gender =
          action.payload.gender === "male"
            ? "Laki-laki"
            : action.payload.gender === "female"
            ? "Perempuan"
            : "";
      if (action.payload.type_param === "kecamatan") {
        pelanggan.kabupaten = action.payload.kabupaten;
        pelanggan.kecamatan = action.payload.kecamatan;
        pelanggan.id_kabupaten = action.payload.id_kabupaten;
        pelanggan.id_kecamatan = action.payload.id_kecamatan;
      }
      if (action.payload.type_param === "toko")
        pelanggan.toko = action.payload.toko;
      if (action.payload.type_param === "alamat")
        pelanggan.alamat = action.payload.alamat;
      return {
        ...state,
        loadingSavePelanggan: false,
        detailPelanggan: pelanggan,
      };
    case constants.GET_TRANSAKSI_PRODUK_PELANGGAN:
      return {
        ...state,
        loadingTransaksiProduk: true,
        forbiddenTransaksiProduk: initialState.forbiddenTransaksiProduk,
      };
    case constants.GET_TRANSAKSI_PRODUK_PELANGGAN_SUCCESS:
      return {
        ...state,
        loadingTransaksiProduk: false,
        transaksiProduk: action.payload.data,
        totalTransaksiProduk: action.payload.total,
      };
    case constants.GET_TRANSAKSI_PRODUK_PELANGGAN_FAILED:
      return {
        ...state,
        loadingTransaksiProduk: false,
        transaksiProduk: initialState.transaksiProduk,
        forbiddenTransaksiProduk: action.payload === 403,
      };
    case constants.GET_TRANSAKSI_DEPOSIT_PELANGGAN:
      return {
        ...state,
        loadingTransaksiDeposit: true,
        forbiddenTransaksiDeposit: initialState.forbiddenTransaksiDeposit,
      };
    case constants.GET_TRANSAKSI_DEPOSIT_PELANGGAN_SUCCESS:
      return {
        ...state,
        loadingTransaksiDeposit: false,
        transaksiDeposit: action.payload.data,
        totalTransaksiDeposit: action.payload.total,
      };
    case constants.GET_TRANSAKSI_DEPOSIT_PELANGGAN_FAILED:
      return {
        ...state,
        loadingTransaksiDeposit: false,
        transaksiDeposit: initialState.transaksiDeposit,
        forbiddenTransaksiDeposit: action.payload === 403,
      };
    case constants.GET_TRANSAKSI_PENGECEKAN_PELANGGAN:
      return {
        ...state,
        loadingTransaksiPengecekan: true,
        forbiddenTransaksiPengecekan: initialState.forbiddenTransaksiPengecekan,
      };
    case constants.GET_TRANSAKSI_PENGECEKAN_PELANGGAN_SUCCESS:
      return {
        ...state,
        loadingTransaksiPengecekan: false,
        transaksiPengecekan: action.payload.data,
        totalTransaksiPengecekan: action.payload.total,
      };
    case constants.GET_TRANSAKSI_PENGECEKAN_PELANGGAN_FAILED:
      return {
        ...state,
        loadingTransaksiPengecekan: false,
        transaksiPengecekan: initialState.transaksiPengecekan,
        forbiddenTransaksiPengecekan: action.payload === 403,
      };
    case constants.GET_MUTASI_SALDO:
      return {
        ...state,
        loadingMutasiSaldo: true,
        forbiddenMutasiSaldo: initialState.forbiddenMutasiSaldo,
      };
    case constants.GET_MUTASI_SALDO_SUCCESS:
      return {
        ...state,
        loadingMutasiSaldo: false,
        mutasiSaldo: action.payload.data,
        totalMutasiSaldo: action.payload.total,
      };
    case constants.GET_MUTASI_SALDO_FAILED:
      return {
        ...state,
        loadingMutasiSaldo: false,
        mutasiSaldo: initialState.mutasiSaldo,
        forbiddenMutasiSaldo: action.payload === 403,
      };
    case constants.GET_LOG_UPDATE_PHONE:
      return {
        ...state,
        loadingLogUpdatePhone: true,
        forbiddenLogUpdatePhone: initialState.forbiddenLogUpdatePhone,
      };
    case constants.GET_LOG_UPDATE_PHONE_SUCCESS:
      return {
        ...state,
        loadingLogUpdatePhone: false,
        logUpdatePhone: action.payload.data,
        totalLogUpdatePhone: action.payload.total,
      };
    case constants.GET_LOG_UPDATE_PHONE_FAILED:
      return {
        ...state,
        loadingLogUpdatePhone: false,
        logUpdatePhone: initialState.logUpdatePhone,
        forbiddenLogUpdatePhone: action.payload === 403,
      };
    case constants.GET_LIST_PERANGKAT:
      return {
        ...state,
        forbiddenPerangkat: initialState.forbiddenPerangkat,
      };
    case constants.GET_LIST_PERANGKAT_SUCCESS:
      return {
        ...state,
        loadingPerangkat: false,
        perangkat: action.payload,
      };
    case constants.GET_LIST_PERANGKAT_FAILED:
      return {
        ...state,
        loadingPerangkat: false,
        perangkat: initialState.perangkat,
        forbiddenPerangkat: action.payload === 403,
      };
    case constants.DELETE_PERANGKAT_SUCCESS:
      newList = [...state.perangkat];
      let filterList = newList.filter((x) => x.id !== action.payload.id);
      return {
        ...state,
        perangkat: filterList,
      };
    case constants.GET_LIST_KEAMANAN:
      return {
        ...state,
        forbiddenKeamanan: initialState.forbiddenKeamanan,
      };
    case constants.GET_LIST_KEAMANAN_SUCCESS:
      return {
        ...state,
        keamanan: action.payload,
        resetPin: false,
      };
    case constants.GET_LIST_KEAMANAN_FAILED:
      return {
        ...state,
        keamanan: initialState.keamanan,
        forbiddenKeamanan: action.payload === 403,
      };
    case constants.DETAIL_OTP_PELANGGAN_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        loadingKeamanan: false,
        otp: {
          code: action.payload.code,
          status: action.payload.status,
          now: action.payload.now,
          expired: action.payload.expired,
          durasi: action.payload.durasi,
        },
        otpList: action.payload.list_otp,
        resetOtp: false,
      };
    case constants.DETAIL_OTP_PELANGGAN_FAILED:
      return {
        ...state,
        loadingKeamanan: false,
        otp: initialState.otp,
        otpList: initialState.otpList,
      };
    case constants.RESET_OTP_SUCCESS:
      return {
        ...state,
        resetOtp: true,
      };
    case constants.RESET_PIN_SUCCESS:
      return {
        ...state,
        resetPin: true,
      };
    case constants.SET_TIME_OTP_SUCCESS:
      return {
        ...state,
        resetOtp: true,
      };
  }
};
