import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "./App.css";
import PrivateRoutes from "./routes/PrivateRoutes";
import { lazy } from "react";
const Main = lazy(() => import("./containers/Main"));
const SignIn = lazy(() => import("./containers/SignIn"));

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <meta name="theme-color" content="#000000" />
          <meta
            name="description"
            content="Kioser adalah distributor pulsa murah, Paket Internet, Paket SMS, Pulsa Transfer, Token PLN, Voucher Game, Saldo Gojek, Saldo Grab >> DAFTAR SEKARANG..."
          />
          {/* <meta name="robots" content="index, follow" /> */}
          <meta name="robots" content="noindex" />
          <meta name="keywords" content="pulsa, paket sms, paket data" />
          <meta name="author" content="Kioser" />
          <meta http-equiv="origin-trial" content="KIOSER" />
        </Helmet>
        <div className="App">
          <Routes>
            <Route
              path="/*"
              element={
                <PrivateRoutes>
                  <Main />
                </PrivateRoutes>
              }
            />
            {/* <Route path="/*" element={<Main />} /> */}
            <Route path="/signin" element={<SignIn />} />
          </Routes>
        </div>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
