export const GET_LIST_SET_METODE = "GET_LIST_SET_METODE";
export const GET_LIST_SET_METODE_SUCCESS = "GET_LIST_SET_METODE_SUCCESS";
export const GET_LIST_SET_METODE_FAILED = "GET_LIST_SET_METODE_FAILED";
export const SET_STATUS_METODE_BT_SUCCESS = "SET_STATUS_METODE_BT_SUCCESS";
export const SET_STATUS_METODE_BT_FAILED = "SET_STATUS_METODE_BT_FAILED";
export const SET_STATUS_METODE_VA_SUCCESS = "SET_STATUS_METODE_VA_SUCCESS";
export const SET_STATUS_METODE_VA_FAILED = "SET_STATUS_METODE_VA_FAILED";
export const SET_STATUS_METODE_QR_SUCCESS = "SET_STATUS_METODE_QR_SUCCESS";
export const SET_STATUS_METODE_QR_FAILED = "SET_STATUS_METODE_QR_FAILED";
export const SET_STATUS_METODE_GR_SUCCESS = "SET_STATUS_METODE_GR_SUCCESS";
export const SET_STATUS_METODE_GR_FAILED = "SET_STATUS_METODE_GR_FAILED";
export const GET_DETAIL_SET_METODE = "GET_DETAIL_SET_METODE";
export const GET_DETAIL_SET_METODE_SUCCESS = "GET_DETAIL_SET_METODE_SUCCESS";
export const GET_DETAIL_SET_METODE_FAILED = "GET_DETAIL_SET_METODE_FAILED";
export const UPDATE_SET_METODE_SUCCESS = "UPDATE_SET_METODE_SUCCESS";
export const UPDATE_SET_METODE_FAILED = "UPDATE_SET_METODE_FAILED";
export const UPDATE_SET_WAKTU_SUCCESS = "UPDATE_SET_WAKTU_SUCCESS";
export const UPDATE_SET_WAKTU_FAILED = "UPDATE_SET_WAKTU_FAILED";
export const UPDATE_REALTIME_SUCCESS = "UPDATE_REALTIME_SUCCESS";
export const UPDATE_REALTIME_FAILED = "UPDATE_REALTIME_FAILED";
export const GET_LIMIT_DEPOSIT_SUCCESS = "GET_LIMIT_DEPOSIT_SUCCESS";
export const GET_LIMIT_DEPOSIT_FAILED = "GET_LIMIT_DEPOSIT_FAILED";
export const UPDATE_LIMIT_DEPOSIT_SUCCESS = "UPDATE_LIMIT_DEPOSIT_SUCCESS";
export const UPDATE_LIMIT_DEPOSIT_FAILED = "UPDATE_LIMIT_DEPOSIT_FAILED";
export const GET_WAKTU_METODE_SUCCESS = "GET_WAKTU_METODE_SUCCESS";
export const GET_WAKTU_METODE_FAILED = "GET_WAKTU_METODE_FAILED";
export const UPDATE_WAKTU_METODE_SUCCESS = "UPDATE_WAKTU_METODE_SUCCESS";
export const UPDATE_WAKTU_METODE_FAILED = "UPDATE_WAKTU_METODE_FAILED";
export const SET_MAINTENANCE_DEPOSIT_SUCCESS =
  "SET_MAINTENANCE_DEPOSIT_SUCCESS";
export const SET_MAINTENANCE_DEPOSIT_FAILED = "SET_MAINTENANCE_DEPOSIT_FAILED";
export const GET_MAINTENANCE_DEPOSIT_SUCCESS =
  "GET_MAINTENANCE_DEPOSIT_SUCCESS";
export const GET_MAINTENANCE_DEPOSIT_FAILED = "GET_MAINTENANCE_DEPOSIT_FAILED";
export const GET_MUTASI = "GET_MUTASI";
export const GET_MUTASI_SUCCESS = "GET_MUTASI_SUCCESS";
export const GET_MUTASI_FAILED = "GET_MUTASI_FAILED";
