import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from "./configureStore";
import { StyleProvider } from "@ant-design/cssinjs";
import { ConfigProvider } from "antd";

const initialState = {};
const store = configureStore(initialState);
const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"DM Sans"',
        },
        hashed: false,
      }}
    >
      <StyleProvider hashPriority="low">
        <App />
      </StyleProvider>
    </ConfigProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
