export const GET_PELANGGAN = "GET_PELANGGAN";
export const GET_PELANGGAN_SUCCESS = "GET_PELANGGAN_SUCCESS";
export const GET_PELANGGAN_FAILED = "GET_PELANGGAN_FAILED";
export const GET_PELANGGAN_BLOKIR = "GET_PELANGGAN_BLOKIR";
export const GET_PELANGGAN_BLOKIR_SUCCESS = "GET_PELANGGAN_BLOKIR_SUCCESS";
export const GET_PELANGGAN_BLOKIR_FAILED = "GET_PELANGGAN_BLOKIR_FAILED";
export const GET_PELANGGAN_VERIFIKASI_SUCCESS =
  "GET_PELANGGAN_VERIFIKASI_SUCCESS";
export const GET_PELANGGAN_VERIFIKASI_FAILED =
  "GET_PELANGGAN_VERIFIKASI_FAILED";
export const BANNED_PELANGGAN_SUCCESS = "BANNED_PELANGGAN_SUCCESS";
export const BANNED_PELANGGAN_FAILED = "BANNED_PELANGGAN_FAILED";
export const VERIFIKASI_PELANGGAN_SUCCESS = "VERIFIKASI_PELANGGAN_SUCCESS";
export const VERIFIKASI_PELANGGAN_FAILED = "VERIFIKASI_PELANGGAN_FAILED";
export const UNVERIFIKASI_PELANGGAN_SUCCESS = "UNVERIFIKASI_PELANGGAN_SUCCESS";
export const UNVERIFIKASI_PELANGGAN_FAILED = "UNVERIFIKASI_PELANGGAN_FAILED";
export const OPEN_BANNED_PELANGGAN_SUCCESS = "OPEN_BANNED_PELANGGAN_SUCCESS";
export const OPEN_BANNED_PELANGGAN_FAILED = "OPEN_BANNED_PELANGGAN_FAILED";
export const DELETE_PELANGGAN_SUCCESS = "DELETE_PELANGGAN_SUCCESS";
export const DELETE_PELANGGAN_FAILED = "DELETE_PELANGGAN_FAILED";

export const GET_TRANSAKSI_PRODUK = "GET_TRANSAKSI_PRODUK";
export const GET_TRANSAKSI_PRODUK_SUCCESS = "GET_TRANSAKSI_PRODUK_SUCCESS";
export const GET_TRANSAKSI_PRODUK_FAILED = "GET_TRANSAKSI_PRODUK_FAILED";
export const GET_TRANSAKSI_DEPOSIT = "GET_TRANSAKSI_DEPOSIT";
export const GET_TRANSAKSI_DEPOSIT_SUCCESS = "GET_TRANSAKSI_DEPOSIT_SUCCESS";
export const GET_TRANSAKSI_DEPOSIT_FAILED = "GET_TRANSAKSI_DEPOSIT_FAILED";
export const GET_METODE_DEPOSIT_SUCCESS = "GET_METODE_DEPOSIT_SUCCESS";
export const GET_METODE_DEPOSIT_FAILED = "GET_METODE_DEPOSIT_FAILED";
export const GET_STATUS_NOBU_SUCCESS = "GET_STATUS_NOBU_SUCCESS";
export const GET_STATUS_NOBU_FAILED = "GET_STATUS_NOBU_FAILED";
export const GET_TRANSAKSI_PENGECEKAN = "GET_TRANSAKSI_PENGECEKAN";
export const GET_TRANSAKSI_PENGECEKAN_SUCCESS =
  "GET_TRANSAKSI_PENGECEKAN_SUCCESS";
export const GET_TRANSAKSI_PENGECEKAN_FAILED =
  "GET_TRANSAKSI_PENGECEKAN_FAILED";
export const GET_DETAIL_TRANSAKSI_PRODUK = "GET_DETAIL_TRANSAKSI_PRODUK";
export const GET_DETAIL_TRANSAKSI_PRODUK_SUCCESS =
  "GET_DETAIL_TRANSAKSI_PRODUK_SUCCESS";
export const GET_DETAIL_TRANSAKSI_PRODUK_FAILED =
  "GET_DETAIL_TRANSAKSI_PRODUK_FAILED";
export const CHANGE_REFERENSI_TRANSAKSI_PRODUK_SUCCESS =
  "CHANGE_REFERENSI_TRANSAKSI_PRODUK_SUCCESS";
export const CHANGE_REFERENSI_TRANSAKSI_PRODUK_FAILED =
  "CHANGE_REFERENSI_TRANSAKSI_PRODUK_FAILED";
export const CHANGE_KETERANGAN_TRANSAKSI_PRODUK_SUCCESS =
  "CHANGE_KETERANGAN_TRANSAKSI_PRODUK_SUCCESS";
export const CHANGE_KETERANGAN_TRANSAKSI_PRODUK_FAILED =
  "CHANGE_KETERANGAN_TRANSAKSI_PRODUK_FAILED";
export const SUKSESKAN_TRANSAKSI_PRODUK_SUCCESS =
  "SUKSESKAN_TRANSAKSI_PRODUK_SUCCESS";
export const SUKSESKAN_TRANSAKSI_PRODUK_FAILED =
  "SUKSESKAN_TRANSAKSI_PRODUK_FAILED";
export const GAGALKAN_TRANSAKSI_PRODUK_SUCCESS =
  "GAGALKAN_TRANSAKSI_PRODUK_SUCCESS";
export const GAGALKAN_TRANSAKSI_PRODUK_FAILED =
  "GAGALKAN_TRANSAKSI_PRODUK_FAILED";
export const GET_DETAIL_TRANSAKSI_DEPOSIT = "GET_DETAIL_TRANSAKSI_DEPOSIT";
export const GET_DETAIL_TRANSAKSI_DEPOSIT_SUCCESS =
  "GET_DETAIL_TRANSAKSI_DEPOSIT_SUCCESS";
export const GET_DETAIL_TRANSAKSI_DEPOSIT_FAILED =
  "GET_DETAIL_TRANSAKSI_DEPOSIT_FAILED";
export const CHANGE_TOTAL_TRANSAKSI_DEPOSIT_SUCCESS =
  "CHANGE_TOTAL_TRANSAKSI_DEPOSIT_SUCCESS";
export const CHANGE_TOTAL_TRANSAKSI_DEPOSIT_FAILED =
  "CHANGE_TOTAL_TRANSAKSI_DEPOSIT_FAILED";
export const SUKSESKAN_TRANSAKSI_DEPOSIT_SUCCESS =
  "SUKSESKAN_TRANSAKSI_DEPOSIT_SUCCESS";
export const SUKSESKAN_TRANSAKSI_DEPOSIT_FAILED =
  "SUKSESKAN_TRANSAKSI_DEPOSIT_FAILED";
export const GAGALKAN_TRANSAKSI_DEPOSIT_SUCCESS =
  "GAGALKAN_TRANSAKSI_DEPOSIT_SUCCESS";
export const GAGALKAN_TRANSAKSI_DEPOSIT_FAILED =
  "GAGALKAN_TRANSAKSI_DEPOSIT_FAILED";
export const GET_DETAIL_TRANSAKSI_PENGECEKAN =
  "GET_DETAIL_TRANSAKSI_PENGECEKAN";
export const GET_DETAIL_TRANSAKSI_PENGECEKAN_SUCCESS =
  "GET_DETAIL_TRANSAKSI_PENGECEKAN_SUCCESS";
export const GET_DETAIL_TRANSAKSI_PENGECEKAN_FAILED =
  "GET_DETAIL_TRANSAKSI_PENGECEKAN_FAILED";

export const GET_LIST_FEEDBACK = "GET_LIST_FEEDBACK";
export const GET_LIST_FEEDBACK_SUCCESS = "GET_LIST_FEEDBACK_SUCCESS";
export const GET_LIST_FEEDBACK_FAILED = "GET_LIST_FEEDBACK_FAILED";
export const GET_LIST_KATEGORI_FEEDBACK_SUCCESS =
  "GET_LIST_KATEGORI_FEEDBACK_SUCCESS";
export const GET_LIST_KATEGORI_FEEDBACK_FAILED =
  "GET_LIST_KATEGORI_FEEDBACK_FAILED";
export const BACKLOG_FEEDBACK_SUCCESS = "BACKLOG_FEEDBACK_SUCCESS";
export const BACKLOG_FEEDBACK_FAILED = "BACKLOG_FEEDBACK_FAILED";
export const CANCEL_BACKLOG_FEEDBACK_SUCCESS =
  "CANCEL_BACKLOG_FEEDBACK_SUCCESS";
export const CANCEL_BACKLOG_FEEDBACK_FAILED = "CANCEL_BACKLOG_FEEDBACK_FAILED";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";
export const DELETE_FEEDBACK_FAILED = "DELETE_FEEDBACK_FAILED";
export const GET_DETAIL_FEEDBACK = "GET_DETAIL_FEEDBACK";
export const GET_DETAIL_FEEDBACK_SUCCESS = "GET_DETAIL_FEEDBACK_SUCCESS";
export const GET_DETAIL_FEEDBACK_FAILED = "GET_DETAIL_FEEDBACK_FAILED";
export const INIT_SALAH_TRANSFER = "INIT_SALAH_TRANSFER";
export const SET_MUTASI_SALAH_TRANSFER = "SET_MUTASI_SALAH_TRANSFER";
export const GET_MUTASI_SALAH_TRANSFER_SUCCESS =
  "GET_MUTASI_SALAH_TRANSFER_SUCCESS";
export const GET_MUTASI_SALAH_TRANSFER_FAILED =
  "GET_MUTASI_SALAH_TRANSFER_FAILED";
export const GET_MUTASI_SALAH_TRANSFER = "GET_MUTASI_SALAH_TRANSFER";
export const SUKSESKAN_SALAH_TRANSFER = "SUKSESKAN_SALAH_TRANSFER";
export const SUKSESKAN_SALAH_TRANSFER_SUCCESS =
  "SUKSESKAN_SALAH_TRANSFER_SUCCESS";
export const SUKSESKAN_SALAH_TRANSFER_FAILED =
  "SUKSESKAN_SALAH_TRANSFER_FAILED";
export const GET_SUPPLIER_REPLY = "GET_SUPPLIER_REPLY";
export const GET_SUPPLIER_REPLY_SUCCESS = "GET_SUPPLIER_REPLY_SUCCESS";
export const GET_SUPPLIER_REPLY_FAILED = "GET_SUPPLIER_REPLY_FAILED";
export const GET_CREATE_TRANSACTION_REPLY = "GET_CREATE_TRANSACTION_REPLY";
export const GET_CREATE_TRANSACTION_REPLY_SUCCESS =
  "GET_CREATE_TRANSACTION_REPLY_SUCCESS";
export const GET_CREATE_TRANSACTION_REPLY_FAILED =
  "GET_CREATE_TRANSACTION_REPLY_FAILED";
export const SET_FILTER_SUPPLIER_REPLY = "SET_FILTER_SUPPLIER_REPLY";
export const RESET_FILTER_SUPPLIER_REPLY = "RESET_FILTER_SUPPLIER_REPLY";
export const GET_LIST_BLACKLIST = "GET_LIST_BLACKLIST";
export const GET_LIST_BLACKLIST_SUCCESS = "GET_LIST_BLACKLIST_SUCCESS";
export const GET_LIST_BLACKLIST_FAILED = "GET_LIST_BLACKLIST_FAILED";
export const ADD_BLACKLIST_SUCCESS = "ADD_BLACKLIST_SUCCESS";
export const ADD_BLACKLIST_FAILED = "ADD_BLACKLIST_FAILED";
export const UPDATE_BLACKLIST_SUCCESS = "UPDATE_BLACKLIST_SUCCESS";
export const UPDATE_BLACKLIST_FAILED = "UPDATE_BLACKLIST_FAILED";
export const OPEN_BLACKLIST_SUCCESS = "OPEN_BLACKLIST_SUCCESS";
export const OPEN_BLACKLIST_FAILED = "OPEN_BLACKLIST_FAILED";

export const GET_LIST_LOGDELETE_USER = "GET_LIST_LOGDELETE_USER";
export const GET_LIST_LOGDELETE_USER_SUCCESS =
  "GET_LIST_LOGDELETE_USER_SUCCESS";
export const GET_LIST_LOGDELETE_USER_FAILED = "GET_LIST_LOGDELETE_USER_FAILED";
export const GET_TOTAL_LOGDELETE_USER_SUCCESS =
  "GET_TOTAL_LOGDELETE_USER_SUCCESS";
export const GET_TOTAL_LOGDELETE_USER_FAILED =
  "GET_TOTAL_LOGDELETE_USER_FAILED";
export const GET_DETAIL_LOGDELETE_USER = "GET_DETAIL_LOGDELETE_USER";
export const GET_DETAIL_LOGDELETE_USER_SUCCESS =
  "GET_DETAIL_LOGDELETE_USER_SUCCESS";
export const GET_DETAIL_LOGDELETE_USER_FAILED =
  "GET_DETAIL_LOGDELETE_USER_FAILED";
export const DELETE_LOGDELETE_USER = "DELETE_LOGDELETE_USER";
export const DELETE_LOGDELETE_USER_SUCCESS = "DELETE_LOGDELETE_USER_SUCCESS";
export const DELETE_LOGDELETE_USER_FAILED = "DELETE_LOGDELETE_USER_FAILED";
export const UPDATE_LOGDELETE_USER = "UPDATE_LOGDELETE_USER";
export const UPDATE_LOGDELETE_USER_SUCCESS = "UPDATE_LOGDELETE_USER_SUCCESS";
export const UPDATE_LOGDELETE_USER_FAILED = "UPDATE_LOGDELETE_USER_FAILED";
export const UPDATE_LOG_DELETE_USER_LOCAL = "UPDATE_LOG_DELETE_USER_LOCAL";
export const DELETE_LOG_DELETE_USER_LOCAL = "DELETE_LOG_DELETE_USER_LOCAL";
export const UPDATE_DETAIL_LOG_DELETE_USER_LOCAL =
  "UPDATE_DETAIL_LOG_DELETE_USER_LOCAL";
